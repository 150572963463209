.siteTitle{
  color: white;

  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  top: 20%;
  position: absolute;
}

.title-appear {
    opacity: 0.01;
  }
  
  .title-appear.title-appear-active {
    opacity: 1;
    transition: opacity 2s ease-in;
  }

h1, h2, h3, h4, h5, h6{
    font-size: calc(50px + 4vmin);
    line-height: 30px; 
    margin-top: 20px;
    margin-bottom: 20px;
    letter-spacing: 5px;
    position: relative;
    z-index: 1;
  }

small {
    font-size: calc(5px + 1vmin);
    letter-spacing: 10px
}

.menu {
  
  align-items: center;
  justify-content: center;
}

.menuButton {
  color: white;
  
  float: left;
  font-size: calc(10px + 1vmin);
  margin-left: 5%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  position: relative;
}

.menuButton:hover {
  background-color: white;
  color: black;
  font-weight: bolder;
}

.doubleBorder {
  border: 1px solid white;
  padding: 3px;
  overflow: auto;

}

.clearfix {
  clear: both;
}

img {
  object-fit: cover;
  z-index: -1;
  position: relative;
}


.icons {
  color: #ffffff ;
  position: absolute;
  top: 1vh;
  right: 1vw;
}

.s3 {
  position: absolute;
  top: 1.5vh;
  left: 1vw;
  height: 100px;
  width: 215px;
  overflow: visible;
  z-index: 5;
}