

/*.App{
  object-fit: cover
}
*/
.App-link {
  color: #61dafb;
}

a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
}

a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

.fill-window {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  overflow: visible;
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  z-index: -1;
}

