.gallery {
    background-color: #ffffff;
    color: #151515;
    margin-top: 18vh;
}

S3Image {
    top: 0;
    left: 0;
    z-index: 20;
    height: 100%;
}