
.nav {
    background-color: #ffffff;
    opacity: 1;
    color: #151515;
    width: 100%;
    height: 13vh;
    left: 1px;
    float: top;
    position: absolute;
}


.navText {
    color: #151515;
    margin-top: 10%;
    cursor: pointer;
    /* border: 1PX solid black; */
}

s3 {
    position: relative;
    left: 1vw;
    height: 100%;
    width: 21.5vw;
    overflow: visible;
    top: 0.75vh;
  }